var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapAlign": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "pageSectionFoot",
      fn: function () {
        return [_c('v-container', {
          staticClass: "container--xxs pa-0"
        }, [_c('v-tabs-items', {
          attrs: {
            "touchless": ""
          },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v;
            },
            expression: "tab"
          }
        }, [_c('v-tab-item', {
          attrs: {
            "value": "form"
          }
        }, [_c('v-form', {
          model: {
            value: _vm.isValid,
            callback: function ($$v) {
              _vm.isValid = $$v;
            },
            expression: "isValid"
          }
        }, [_c('page-section-primary', {
          staticClass: "page-section--xs py-0",
          attrs: {
            "titWrapAlign": "text-left",
            "titWrapSize": "tit-wrap--xs",
            "titSize": "tit--sm"
          },
          scopedSlots: _vm._u([{
            key: "pageSectionHead",
            fn: function () {
              return [_c('v-container', [_c('txt-primary', {
                staticClass: "text-right mt-0 pb-16px pb-md-20px",
                attrs: {
                  "txtSize": "txt--sm"
                }
              }, [_c('span', {
                staticClass: "red--text"
              }, [_vm._v("* ")]), _vm._v("필수입력 항목입니다.")])], 1)];
            },
            proxy: true
          }, {
            key: "tit",
            fn: function () {
              return [_vm._v("기업정보")];
            },
            proxy: true
          }])
        }, [_c('tit-form-section', {
          attrs: {
            "items": _vm.corporations
          },
          on: {
            "certification": function (value) {
              return _vm.form._certification = value;
            }
          },
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v;
            },
            expression: "form"
          }
        }, [_c('template', {
          slot: "사업자등록증"
        }, [_c('v-file-input', _vm._b({
          attrs: {
            "required": "",
            "accept": _vm.allowedFileTypes.join(', '),
            "placeholder": "파일을 첨부하세요."
          },
          on: {
            "change": _vm.fileValidate
          },
          model: {
            value: _vm.form.company.businessLicense,
            callback: function ($$v) {
              _vm.$set(_vm.form.company, "businessLicense", $$v);
            },
            expression: "form.company.businessLicense"
          }
        }, 'v-file-input', Object.assign({}, _vm.attrs_file_input_common, {
          rules: _vm.businessLicensRrules
        }), false)), _c('div', [_vm._v("[사업자등록증 파일은 pdf, jpg, png 형식의 파일만 2MB 이하로 등록이 가능합니다.]")]), _c('div', [_vm._v("[회원가입 신청 완료 후 관리자의 승인 처리 이후에 이용이 가능합니다.]")])], 1), _c('template', {
          slot: "주소"
        }, [_c('v-address-field', {
          attrs: {
            "outlined": "",
            "persistent-placeholder": "",
            "btnAttrs": {
              large: true,
              color: 'primary'
            }
          },
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v;
            },
            expression: "form"
          }
        })], 1)], 2)], 1), _c('page-section-primary', {
          staticClass: "page-section--xs pb-0",
          attrs: {
            "titWrapAlign": "text-left",
            "titWrapSize": "tit-wrap--xs",
            "titSize": "tit--sm"
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v("개인정보")];
            },
            proxy: true
          }])
        }, [_c('tit-form-section', _vm._b({
          on: {
            "certification": function (value) {
              return _vm.form._certification = value;
            },
            "name": function (value) {
              return _vm.form.name = value;
            }
          },
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v;
            },
            expression: "form"
          }
        }, 'tit-form-section', {
          items: _vm.items
        }, false), [_c('template', {
          slot: "비밀번호 확인"
        }, [_c('v-password-confirm-field', {
          attrs: {
            "password": _vm.form.password,
            "placeholder": "동일한 비밀번호를 재입력하세요."
          }
        })], 1), _c('template', {
          slot: "직급·직책"
        }, [_c('v-row', {
          staticClass: "row--xxs"
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('v-text-field', _vm._b({
          attrs: {
            "dense": false,
            "placeholder": "직급을 입력해주세요."
          },
          model: {
            value: _vm.form.position,
            callback: function ($$v) {
              _vm.$set(_vm.form, "position", $$v);
            },
            expression: "form.position"
          }
        }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
          loading: _vm.loading
        }), false))], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('v-text-field', _vm._b({
          attrs: {
            "dense": false,
            "placeholder": "직책을 입력해주세요."
          },
          model: {
            value: _vm.form.job,
            callback: function ($$v) {
              _vm.$set(_vm.form, "job", $$v);
            },
            expression: "form.job"
          }
        }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
          loading: _vm.loading
        }), false))], 1)], 1)], 1)], 2)], 1), _c('page-section-primary', {
          staticClass: "page-section--xs pb-0",
          attrs: {
            "titWrapAlign": "text-left",
            "titWrapSize": "tit-wrap--xs",
            "titSize": "tit--sm"
          }
        }, [_c('terms-of-agreements-short', {
          on: {
            "isValid": function (isValid) {
              return _vm.isTermsValid = isValid;
            }
          },
          model: {
            value: _vm.form._terms,
            callback: function ($$v) {
              _vm.$set(_vm.form, "_terms", $$v);
            },
            expression: "form._terms"
          }
        })], 1), _c('page-section-primary', {
          staticClass: "page-section--xs pb-0",
          attrs: {
            "titWrapAlign": "text-left",
            "titWrapSize": "tit-wrap--xs",
            "titSize": "tit--sm"
          }
        }, [_c('v-row', {
          staticClass: "row--sm",
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', [_c('v-btn', _vm._b({
          attrs: {
            "x-large": "",
            "block": ""
          },
          on: {
            "click": function ($event) {
              return _vm.$router.go(-1);
            }
          }
        }, 'v-btn', Object.assign({}, _vm.btn_tertiary), false), [_vm._v("취소하기")])], 1), _c('v-col', [_c('v-btn', _vm._b({
          attrs: {
            "x-large": "",
            "block": ""
          },
          on: {
            "click": _vm.join
          }
        }, 'v-btn', Object.assign({}, _vm.btn_primary, {
          disabled: _vm.disabled
        }), false), [_vm._v("회원가입하기")])], 1)], 1)], 1)], 1)], 1), _c('v-tab-item', {
          attrs: {
            "value": "result"
          }
        }, [_c('div', {
          staticClass: "text-center"
        }, [_c('div', {
          staticClass: "mb-18px mb-md-24px"
        }, [_c('icon-check')], 1), _c('tit-wrap-primary', {
          attrs: {
            "titWrapClass": "pb-0",
            "txtClass": "line-height-15"
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 회원가입이 "), _c('br', {
                staticClass: "d-sm-none"
              }), _vm._v("완료되었습니다. ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 관리자 승인 후 기업서비스를 이용하실 수 있습니다."), _c('br'), _vm._v("1~2 영업일 이내에 승인여부를 확인 하실 수 있습니다. ")];
            },
            proxy: true
          }])
        }), _c('div', {
          staticClass: "btn-wrap"
        }, [_c('v-row', {
          staticClass: "row--sm",
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "6",
            "sm": "auto"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "w-100 min-w-sm-200px",
          attrs: {
            "x-large": "",
            "to": "/"
          }
        }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("상품발송 페이지로 이동")])], 1)], 1)], 1)], 1)])], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v("기업회원가입")])], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }