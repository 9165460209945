<template>
    <client-page>
        <page-section titWrapAlign="text-center" class="page-section--first page-section--last">
            <template slot="tit">기업회원가입</template>

            <template #pageSectionFoot>
                <v-container class="container--xxs pa-0">
                    <!-- <div class="tabs-wrap">
                        <v-btn-toggle mandatory group class="grey darken-4">
                            <v-btn class="v-size--xx-large w-50">개인회원</v-btn>
                            <v-btn class="v-size--xx-large w-50">기업회원</v-btn>
                        </v-btn-toggle>
                    </div> -->

                    <v-tabs-items v-model="tab" touchless>
                        <!-- 회원가입정보입력 -->
                        <v-tab-item value="form">
                            <v-form v-model="isValid">
                                <page-section-primary titWrapAlign="text-left" titWrapSize="tit-wrap--xs" titSize="tit--sm" class="page-section--xs py-0">
                                    <template #pageSectionHead>
                                        <v-container>
                                            <txt-primary txtSize="txt--sm" class="text-right mt-0 pb-16px pb-md-20px"><span class="red--text">* </span>필수입력 항목입니다.</txt-primary>
                                        </v-container>
                                    </template>
                                    <template #tit>기업정보</template>
                                    <tit-form-section v-model="form" :items="corporations" @certification="(value) => (form._certification = value)">
                                        <!-- <template slot="사업자등록번호">
                                            <v-text-field v-model="form.company.businessNo" v-bind="{ ...attrs_input }" required placeholder="사업자등록번호 입력" />
                                        </template> -->
                                        <template slot="사업자등록증">
                                            <v-file-input v-model="form.company.businessLicense" v-bind="{ ...attrs_file_input_common, rules: businessLicensRrules }" required :accept="allowedFileTypes.join(', ')" placeholder="파일을 첨부하세요."  @change="fileValidate" />
                                            <div>[사업자등록증 파일은 pdf, jpg, png 형식의 파일만 2MB 이하로 등록이 가능합니다.]</div>
                                            <div>[회원가입 신청 완료 후 관리자의 승인 처리 이후에 이용이 가능합니다.]</div>
                                        </template>
                                        <template slot="주소">
                                            <v-address-field v-model="form" outlined persistent-placeholder :btnAttrs="{ large: true, color: 'primary' }" />
                                        </template>
                                    </tit-form-section>
                                </page-section-primary>

                                <page-section-primary titWrapAlign="text-left" titWrapSize="tit-wrap--xs" titSize="tit--sm" class="page-section--xs pb-0">
                                    <template #tit>개인정보</template>
                                    <tit-form-section v-model="form" v-bind="{ items }" @certification="(value) => (form._certification = value)" @name="(value) => (form.name = value)">
                                        <template slot="비밀번호 확인">
                                            <v-password-confirm-field :password="form.password" placeholder="동일한 비밀번호를 재입력하세요." />
                                        </template>
                                        <template slot="직급·직책">
                                            <v-row class="row--xxs">
                                                <v-col cols="12" md="6">
                                                    <v-text-field v-model="form.position" v-bind="{ ...attrs_input, loading }" :dense="false" placeholder="직급을 입력해주세요." />
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-text-field v-model="form.job" v-bind="{ ...attrs_input, loading }" :dense="false" placeholder="직책을 입력해주세요." />
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </tit-form-section>
                                </page-section-primary>

                                <page-section-primary titWrapAlign="text-left" titWrapSize="tit-wrap--xs" titSize="tit--sm" class="page-section--xs pb-0">
                                    <terms-of-agreements-short v-model="form._terms" @isValid="(isValid) => (isTermsValid = isValid)" />
                                </page-section-primary>

                                <page-section-primary titWrapAlign="text-left" titWrapSize="tit-wrap--xs" titSize="tit--sm" class="page-section--xs pb-0">
                                    <v-row justify="center" class="row--sm">
                                        <v-col>
                                            <v-btn v-bind="{ ...btn_tertiary }" x-large block @click="$router.go(-1)">취소하기</v-btn>
                                        </v-col>
                                        <v-col>
                                            <v-btn v-bind="{ ...btn_primary, disabled }" x-large block @click="join">회원가입하기</v-btn>
                                        </v-col>
                                    </v-row>
                                </page-section-primary>
                            </v-form>
                        </v-tab-item>
                        <!-- 회원가입완료 -->
                        <v-tab-item value="result">
                            <div class="text-center">
                                <div class="mb-18px mb-md-24px">
                                    <icon-check />
                                </div>
                                <tit-wrap-primary titWrapClass="pb-0" txtClass="line-height-15">
                                    <template #tit> 회원가입이 <br class="d-sm-none" />완료되었습니다. </template>
                                    <template #txt> 관리자 승인 후 기업서비스를 이용하실 수 있습니다.<br />1~2 영업일 이내에 승인여부를 확인 하실 수 있습니다. </template>
                                </tit-wrap-primary>

                                <div class="btn-wrap">
                                    <v-row justify="center" class="row--sm">
                                        <v-col cols="6" sm="auto">
                                            <v-btn v-bind="{ ...btn_primary }" x-large to="/" class="w-100 min-w-sm-200px">상품발송 페이지로 이동</v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </v-tab-item>
                    </v-tabs-items>
                </v-container>
            </template>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { attrs_input, btn_primary, btn_secondary, btn_tertiary, initUser, rules, attrs_file_input_common } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitFormSection from "@/components/dumb/tit-form-section.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import VAddressField from "@/components/plugins/vuetify/v-address-field.vue";
import VPasswordConfirmField from "@/components/plugins/vuetify/v-password-confirm-field.vue";
import VPhoneField from "@/components/plugins/vuetify/v-phone-field.vue";
import TermsOfAgreementsShort from "@/components/client/join/terms-of-agreements-short.vue";
import IconCheck from "@/components/publish/styles/icons/icon-check.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";

const corporations = [
    {
        key: "company.businessNo",
        term: "사업자등록번호",
        type: "text",
        required: true,
        placeholder: "사업자등록번호 입력",
        rules: rules.businessNo
    },
    {
        key: "company.businessLicense",
        term: "사업자등록증",
        type: "file",
        required: true,
        placeholder: "파일을 첨부하세요",
        rules: rules.businessLicense
    },
    {
        key: "company.name",
        term: "기업명",
        type: "text",
        required: true,
        placeholder: "기업명을 입력해주세요.",
        rules: rules.companyName
    },
    {
        key: "company.ceo",
        term: "대표자 성명",
        type: "text",
        required: true,
        placeholder: "대표자 성명을 입력해주세요.",
        rules: rules.companyCeo
    },
    {
        term: "주소",
        type: "address",
        required: true,
    },
].map((item) => ({ ...item, outlined: true }));

const items = [
    {
        key: "username",
        term: "아이디",
        type: "text",
        required: true,
        placeholder: "아이디를 입력하세요.",
        rules: rules.username,
    },
    {
        key: "password",
        term: "비밀번호",
        type: "password",
        required: true,
        placeholder: "비밀번호를 입력하세요.",
        rules: rules.password,
    },
    {
        key: "password",
        term: "비밀번호 확인",
        required: true,
        placeholder: "동일한 비밀번호를 재입력하세요.",
    },
    {
        key: "phone",
        term: "연락처",
        type: "phone",
        required: true,
        hasCertification: true,
    },
    {
        key: "name",
        term: "담당자명",
        type: "text",
        required: true,
        readonly: true,
        placeholder: "담당자명을 입력해주세요.",
    },
    {
        key: "tel",
        term: "유선전화번호",
        type: "text",
        required: false,
        placeholder: "유선전화번호를 입력해주세요.",
    },
    {
        key: "email",
        term: "이메일",
        type: "email",
        required: true,
        placeholder: "이메일을 입력하세요.",
        rules: rules.email,
    },
    {
        term: "직급·직책",
        type: "text",
        required: false,
    },
].map((item) => ({ ...item, outlined: true }));

export default {
    components: {
        ClientPage,
        PageSection,
        TitFormSection,
        VerticalFormTable,
        VAddressField,
        VPasswordConfirmField,
        VPhoneField,
        TermsOfAgreementsShort,
        IconCheck,
        TxtPrimary,
        PageSectionPrimary,
        TitWrapPrimary,
    },
    data: () => ({
        attrs_input,
        btn_primary,
        btn_secondary,
        btn_tertiary,
        attrs_file_input_common,

        form: initUser(),

        tab: "form",
        // tab: "result",

        phone: null,
        otp: null,

        items,
        corporations,
        isValid: false,
        isTermsValid: undefined,

        loading: false,
        allowedFileTypes: ["pdf", "jpg", "png"]
    }),
    computed: {
        promoterCode() {
            return this.$route.query.promoterCode || null;
        },
        disabled() {
            return !this.isValid || !this.isTermsValid;
        },
        businessLicensRrules() {
            return [() => (!!this.form.company.businessLicense) || "파일을 선택해주세요"];
        },
    },
    mounted() {
        let { promoterCode } = this;
        this.form = initUser({ promoterCode });
    },
    methods: {
        validate() {
            try {
                if (!this.form.company.businessNo) throw new Error("사업자등록번호를 입력해주세요");
                if (!this.form.company.businessLicense) throw new Error("사업자등록증을 업로드해주세요");
                if (!this.form.company.name) throw new Error("기업명을 입력해주세요");
                if (!this.form.company.ceo) throw new Error("대표자 성명을 입력해주세요");
                if (!this.form.company.ceo) throw new Error("대표자 성명을 입력해주세요");
                if (!this.form.username) throw new Error("아이디를 입력해주세요");
                if (!this.form.password) throw new Error("비밀번호를 입력해주세요");
                if (this.form.password !== this.form.confirmPassword) throw new Error("비밀번호를 입력해주세요");
                return true;
            } catch (error) {
                alert(error.message)
                return false;
            }
        },
        validates() {
            try {
                switch (this.isTermsValid) {
                    case undefined:
                        throw new Error("이용약관을 확인해주세요");
                    case false:
                        throw new Error("필수 이용약관에 모두 동의해주세요");
                    default:
                        break;
                }
                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async join() {
            if (!this.validates()) return;
            if (this.loading) return;
            this.loading = true;

            try {
                const form = {
                    ...this.form,
                    company: {
                        businessLicense: null,
                        businessNo: this.form.company.businessNo,
                        name: this.form.company.name,
                        ceo: this.form.company.ceo,
                    },
                    password: CryptoAES.encrypt(this.form.password),
                };

                let { user } = await api.v1.users.post(form);
                if (this.form.company.businessLicense) {
                    console.log("파일")
                    await api.v1.users.files.post(user._id, this.form.company.businessLicense)
                }

                this.tab = "result";
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        async certify() {
            if (this.validate()) {
                let { phone } = this;
                await api.v1.users.certify.post({
                    type: "phone",
                    kind: "username",
                    phone,
                });

                alert("인증번호가 전송되었습니다");
            }
        },
        async recovery() {
            if (this.validate(true)) {
                let { phone, otp } = this;
                await api.v1.users.recovery.post({
                    type: "phone",
                    kind: "username",
                    phone,
                    otp,
                });

                alert("휴대전화번호로 아이디가 발송되었습니다");
            }
        },
        fileValidate() {
            if (this.form.company.businessLicense) {
                const fileSize = this.form.company.businessLicense.size; // 파일 크기 (바이트 단위)

                // 파일 확장자 검증
                const fileExtension = this.form.company.businessLicense.name.split(".").pop().toLowerCase();
                if (!this.allowedFileTypes.includes(fileExtension)) {
                    this.form.company.businessLicense = null; // 파일 선택 취소
                    alert("허용되지 않는 파일 확장자입니다.");
                    return;
                }

                // 파일 크기 검증 (1MB로 제한)
                if (fileSize > 2 * 1024 * 1024) {
                    this.form.company.businessLicense = null; // 파일 선택 취소
                    alert("파일 크기가 너무 큽니다. 2MB 이하의 파일을 선택해주세요.");
                    return;
                }
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.v-btn-toggle {
    width: 100%;
    border-radius: var(--btn-border-radius-xxl);
    overflow: hidden;
    > .v-btn.v-btn {
        margin: 0;
        border-radius: 0;
        opacity: 1;
        border-width: var(--btn-outline-border-width);
        background-color: #fff !important;
        border-color: inherit;
        &--active {
            color: #fff !important;
            background-color: inherit !important;
            &:before {
                opacity: 0;
            }
        }
        &.v-size--xx-large {
            &:first-child {
                border-top-left-radius: var(--btn-border-radius-xxl);
                border-bottom-left-radius: var(--btn-border-radius-xxl);
            }
            &:last-child {
                border-top-right-radius: var(--btn-border-radius-xxl);
                border-bottom-right-radius: var(--btn-border-radius-xxl);
            }
        }
    }
}
</style>
